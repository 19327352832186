import isServer from './isServer'

export const isMobile = (): boolean => {
  return !isServer() && window.innerWidth <= 768
}
export const isIPad = (): boolean => {
  const isIpadSafari =
    !isServer() &&
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1

  const isIPadChrome = !isServer() && /iPad/i.test(navigator.userAgent)

  return isIPadChrome || isIpadSafari
}
