import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Pagination,
  Navigation,
  Mousewheel,
  Lazy,
  Controller,
  Virtual
} from 'swiper/core'
import { SwiperOptions } from 'swiper'

SwiperCore.use([Pagination, Navigation, Mousewheel, Lazy, Controller, Virtual])

import SongComponent from './SongComponent'
import blockStyles from './_block.module.scss'
import styles from './_trendingSwiper.module.scss'
import { isMobile } from '../utils/deviceDetection'
import { songsPath } from '../utils/routeHelpers'
import { FilterValues } from './ui/ListSort'
import { t } from '../utils'
import Link from 'next/link'

const TrendingSwiper: React.FC<any> = ({ songs: trendingSongs }) => {
  if (!trendingSongs) return <></>

  const params: SwiperOptions = {
    slidesPerView: 2,
    slidesPerGroup: 4,
    spaceBetween: 20,
    navigation: true,
    mousewheel: !isMobile()
      ? {
          forceToAxis: true
        }
      : false,
    watchSlidesVisibility: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    freeMode: true,
    breakpoints: {
      390: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 25
      },
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        spaceBetween: 32
      },
      1200: {
        slidesPerView: 6,
        slidesPerGroup: 4,
        spaceBetween: 32
      }
    }
  }

  const songs = trendingSongs.map((song, index) => {
    return (
      <SwiperSlide key={song.id} virtualIndex={index}>
        <SongComponent song={song} displayEditButton={true} />
      </SwiperSlide>
    )
  })

  return (
    <section className={`${blockStyles.block} ${styles.trending} column`}>
      <Link
        href={songsPath({
          orderBy: FilterValues.CURRENTLY_TRENDING
        })}
        prefetch={false}
        rel="noreferrer"
      >
        <header className={blockStyles.header}>
          <h2>{t('Trending')}</h2>
          <div className={blockStyles.moreLink}>{t('View All')} ▸</div>
        </header>
      </Link>

      <div className={styles.trendingSwiper}>
        <Swiper virtual {...params}>
          {songs}
        </Swiper>
      </div>
    </section>
  )
}

export default TrendingSwiper
